(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:TownHallAssociated
   *
   * @description
   *
   */
  angular
      .module('neo.services')
      .factory('TownHallAssociated', TownHallAssociated);

  function TownHallAssociated($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/entities/:id/townHall', {id: '@_id'}, {
          query: {
            method: 'GET',
            isArray: false
          }
        }
    );
  }
}());